.center {
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main {
    display: flex;
    flex-direction: column;
}

.margin-1 {
    margin: 1rem;
}

.password-settings {
    margin: 1rem;
    padding: 3rem
}

@media screen and (max-width: 480px) {
    .password-settings {
        padding: 2rem
    }
  }